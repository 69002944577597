/* eslint-disable eqeqeq */
import $ from "jquery";
import orderListUtil, {
  updatePrintersStatus,
  getTableOccupation,
  update,
  tmplparams,
  setPrinterForPrinterAction,
  unsetPrinterForProductionLine,
  setPrinterForProductionLine,
  unsetPrinterForPrinterAction,
  printNewOrders,
  addRejectionReason,
  simpleChangeStateByTableAndMenuItem,
  setTakeAway,
  setTakeAwayOrder,
  playClickSound,
  getTableName
} from "./order-list-util";
import auth, {
  ajaxCallStart,
  startpoll,
  ajaxCallEnd,
  choiceDialog2,
  avoidMultipleClick,
  isDuplicateClickEvent,
  cleanLogin,
  relogin,
  avoidMultipleClick2,
  touchStart,
  touchClick,
  deactivateToggleRadioButton,
  ajaxErrorEnd,
  settingEnabled,
  featureEnabled,
  getQuantityLabel,
  lighten,
  scaleMeasure
} from "./auth";
import admin, { getTableOccupations, getTableMate, unlockTable, lockTable, isAdmin, getDiscount, unlockme, changeToUser, unlock, getProductionLine, getMenuItem, getMenuCategory } from "./admin";
import {
  serverSideMessageHandler,
  setBigTableNumber,
  resize,
  processTableOccupations,
  topUpdateHooks,
  hideSelectNewOrders,
  getStateIndex,
  getOpenOrdersCount,
  selectNewOrders,
  afterLockTable,
  editDiscounts,
  markTableMateForPayment,
  checkPrint,
  tableMatePaid,
  submitNewOrders,
  printQRCode,
  changeLayout,
  showTableOccupationList,
  increase,
  decrease,
  selectTableOccupation,
  activeMenuItems,
  activeMenuCategories,
  getMenuCategoryOptions,
  tablesOverview,
  setSelectedScale
} from "./tables-overview";
import chooseMenuItems, { showMenuItems, showMenuItemCategory, gotoTop, onsave } from "./chooseMenuItem";
import languages from "../langs/languages";
import SortedSet from "js-sorted-set";
import { customerComparator } from "./order-grouping";
import { hideEditTable as fullHideEditTable, show, updateOrdersDone, processOrdersDone } from "./tables-overview";
import { orderAnother, editOrder, orderMenuItem, additionSearchChanged, includedInCategoryOption, additionOptionIsChecked, switchKeyboard, deleteOne } from "./orderMenuItem";
import tableLayout from "./table-layout";
import { setAmount, selectCurrency, amountChanged, checkPayments, isThereReceiptsToPrint, reprintReceipt, dayOpen, dayClose, storno, selectTerminal } from "./tableMatePaid";
import { chooseCustomer } from "./choose_customer";
//import BookTable from "./bookTable";
import menuitemutil from "./admin/menuitem";
import { insertCharacter, insertEnter, backspaceCharacter, leftCharacter, rightCharacter, clearInput } from "./keyboard";
import { cashierChanged } from "./admin/tableOccupations";
import moment from "moment";
import { I18n } from "react-redux-i18n";

const BookTable = {};

const { admin_local, local } = languages;

export const stopIntervals = () => { };

var updateCustomerScreen;

export const ready = (props, context) => {

  tablesOverview.context = context;

  orderListUtil.kioskMode = true;
  $("script#editTableTemplate")
    .tmpl(tmplparams())
    .appendTo("body");

  updateCustomerScreen = props.updateCustomerScreen;

  window.hideEditTable = hideEditTable;
  window.avoidMultipleClick = avoidMultipleClick;
  window.unlockTable = unlockTable;
  window.tableMatePaid = tableMatePaid;
  window.editOrder = editOrder;
  window.isDuplicateClickEvent = isDuplicateClickEvent;
  window.editDiscounts = editDiscounts;
  window.getDiscount = getDiscount;
  window.selectNewOrders = selectNewOrders;
  window.markTableMateForPayment = markTableMateForPayment;
  window.checkPrint = checkPrint;
  window.admin = admin;
  window.update = update;
  window.getTable = getTable;
  window.setAmount = setAmount;
  window.moment = moment;
  window.showMenuItemCategory = showMenuItemCategory;
  window.gotoTop = gotoTop;
  window.orderMenuItem = orderMenuItem;
  window.additionSearchChanged = additionSearchChanged;
  window.submitNewOrders = submitNewOrders;
  window.unlockme = unlockme;
  window.changeToUser = changeToUser;
  window.cleanLogin = cleanLogin;
  window.relogin = relogin;
  window.setPrinterForPrinterAction = setPrinterForPrinterAction;
  window.unsetPrinterForProductionLine = unsetPrinterForProductionLine;
  window.printQRCode = printQRCode;
  window.setPrinterForProductionLine = setPrinterForProductionLine;
  window.unsetPrinterForPrinterAction = unsetPrinterForPrinterAction;
  window.chooseCustomer = chooseCustomer;
  window.avoidMultipleClick2 = avoidMultipleClick2;
  window.printNewOrders = printNewOrders;
  window.unlock = unlock;
  window.getProductionLine = getProductionLine;
  window.touchStart = touchStart;
  window.touchClick = touchClick;
  window.orderAnother = orderAnother;
  window.addRejectionReason = addRejectionReason;
  window.changeLayout = changeLayout;
  window.showTableOccupationList = showTableOccupationList;
  window.getMenuItem = getMenuItem;
  window.getTableMate = getTableMate;
  window.simpleChangeStateByTableAndMenuItem = simpleChangeStateByTableAndMenuItem;
  window.deactivateToggleRadioButton = deactivateToggleRadioButton;
  window.increase = increase;
  window.decrease = decrease;
  window.BookTable = BookTable;
  window.lockTable = lockTable;
  window.afterLockTable = afterLockTable;
  window.menuitemutil = menuitemutil;
  window.onsave = onsave;
  window.setTakeAway = setTakeAway;
  window.orderListUtil = orderListUtil;
  window.cancelOrders = cancelOrders;
  window.selectTableOccupation = selectTableOccupation2;
  window.ajaxErrorEnd = ajaxErrorEnd;
  window.settingEnabled = settingEnabled;
  window.closeOrder = closeOrder;
  window.selectCurrency = selectCurrency;
  window.amountChanged = amountChanged;
  window.cashierChanged = cashierChanged;
  window.checkPayments = checkPayments;
  window.showMenuItems = showMenuItems;
  window.includedInCategoryOption = includedInCategoryOption;
  window.featureEnabled = featureEnabled;
  window.orderMenuItem = orderMenuItem;
  window.getQuantityLabel = getQuantityLabel;
  window.includedInCategoryOption = includedInCategoryOption;
  window.getActiveMenuItems = activeMenuItems;
  window.getActiveMenuCategories = activeMenuCategories;
  window.getMenuCategory = getMenuCategory;
  window.getMenuCategoryOptions = getMenuCategoryOptions;
  window.additionOptionIsChecked = additionOptionIsChecked;
  window.switchKeyboard = switchKeyboard;
  window.insertCharacter = insertCharacter;
  window.insertEnter = insertEnter;
  window.backspaceCharacter = backspaceCharacter;
  window.leftCharacter = leftCharacter;
  window.rightCharacter = rightCharacter;
  window.clearInput = clearInput;
  window.setTakeAwayOrder = setTakeAwayOrder;
  window.playClickSound = playClickSound;
  window.reprintReceipt = reprintReceipt;
  window.dayOpen = dayOpen;
  window.dayClose = dayClose;
  window.storno = storno;
  window.selectTerminal = selectTerminal;
  window.getTableName = getTableName;
  window.lighten = lighten;
  window.scaleMeasure = scaleMeasure;
  window.setSelectedScale = setSelectedScale;
  window.deleteOne = deleteOne;

  auth.resize = resize;

  delete localStorage.tableOccupationSelected;

  const def = $.Deferred();

  ajaxCallStart("processing1");
  try {
    try {
      if (typeof orderListUtil.occupationsSelected == "undefined") orderListUtil.occupationsSelected = [];

      orderListUtil.ai = props.ai;
      tableLayout.tables = props.tables;
      orderListUtil.tablesOverview = false;

      orderListUtil.states = auth.myStatus.restaurant_settings["tables-overview"].states;
      orderListUtil.statesShownOriginal = orderListUtil.statesShown = auth.myStatus.restaurant_settings["tables-overview"].statesShown;
      orderListUtil.hidePaid = true;

      orderListUtil.processOrdersDone = processOrdersDone;
      orderListUtil.updateOrdersDone = updateOrdersDone;
      orderListUtil.hideSelectNewOrders = hideSelectNewOrders;
      orderListUtil.show = show;
      //orderListUtil.filter = filter;
      orderListUtil.orderAnother = orderAnother;
      orderListUtil.deleteOne = deleteOne;

      startpoll(serverSideMessageHandler, [
        {
          Restaurant: localStorage.restaurantSelected,
          TableOccupation: "*"
        },
        {
          Restaurant: localStorage.restaurantSelected,
          Menu: "*"
        },
        {
          Restaurant: localStorage.restaurantSelected,
          Settings: "*"
        },
        {
          Restaurant: localStorage.restaurantSelected,
          Printer: "*"
        }
      ]);
      // $('#restaurantStyle').text(myStatus.restaurant_css);

      $("script#pointOfSaleMainTemplate")
        .tmpl(tmplparams())
        .appendTo("div#main");
      $("#main #right_pane .keyboard").hide();
      $("#pointOfSaleMain .bar").replaceWith($("script#pointOfSaleMainBarTemplate").tmpl(tmplparams()));

      ajaxCallStart("processing2");
      try {
        ajaxCallStart("processing21");
        admin.getTables(function (tableLayouts) {
          try {
            orderListUtil.pointOfSales = [];

            tableLayouts.restaurantTables.forEach(table => {
              if (table.pos == true) orderListUtil.pointOfSales.push(table);
            });
            processRestaurant().done(def.resolve);

            setBigTableNumber();
          } finally {
            ajaxCallEnd("processing21");
          }
        });

        // canvas = new fabric.Canvas('c');
        // canvas.selection = true; // disable group selection

        // initializeLayout();

        $("#editTable #right_pane input#search").keyup(function (e) {
          showMenuItems($(this).val());
          e.preventDefault();
          e.stopPropagation();
        });
        resize(1);
      } catch (ex) {
        alert(ex);
      }

      $("#language.dropdown-menu").empty();
      auth.myStatus.restaurant_languages.forEach(function (lang) {
        $("#language.dropdown-menu").append('<button onclick="pickLanguage(&quot;' + lang + '&quot;)"  class="dropdown-item">' + lang + "</button>");
      });
      $("button#language").text(localStorage.language);
      admin.getPrintersStatus(updatePrintersStatus);

      $("#timingSelector").hide();

      selectNewOrders(true);
    } finally {
      ajaxCallEnd("processing2");
    }
  } finally {
    ajaxCallEnd("processing1");
  }
  return def.promise();

};

function processRestaurant() {
  const def = $.Deferred();
  ajaxCallStart("processing51");
  admin.resync([], (data) => {
    //getTableOccupations(data => {
    try {
      processTableOccupations(data);
    } finally {
      ajaxCallEnd("processing51");
    }
  });
  orderListUtil.updateHooks();
  topUpdateHooks();
  return def.promise();
}

export const hideEditTable = force => {
  if (typeof force == "undefined") force = false;

  const isPickup = Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].pickup["table-number"];
  const isHomeDelivery = Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"];

  if (!$("#right_pane").hasClass("hidden")) {
    if (force) {
      hideSelectNewOrders();
    } else {
      var thereAreOrders = false;
      if (typeof orderListUtil.orders != "undefined" && typeof orderListUtil.orders.tables != "undefined")
        orderListUtil.ordersCache.forEach(function (order) {
          if (order.state === "selected" && localStorage.tableOccupationSelected == order.tableOccupationId && localStorage.tableNumberSelected == order.tableNumber) {
            thereAreOrders = true;
          }
        });

      if (thereAreOrders) {
        choiceDialog2(local.confirmation, local.do_you_really_want_to_quit_pending_orders, [admin_local.suspend_ordering]).done(function () {
          hideSelectNewOrders();
        });
      } else {
        hideSelectNewOrders();
      }
      return;
    }
    return;
  }

  if (isPickup || isHomeDelivery) {
    hideSelectNewOrders();
    $('#main').hide();
    return;
  }
};

export const updateFooterButtons = (first) => {
  if (!auth.myStatus)
    return;
  if (updateCustomerScreen) {
    if (orderListUtil.orders && orderListUtil.orders.tables) {
      var res = orderListUtil.orders.tables.filter(o => Number(o.tableNumber) === Number(localStorage.tableNumberSelected) && Number(o.tableOccupation) === Number(localStorage.tableOccupationSelected));
      if (res)
        res.map(o => {
          o.orders.forEach(o => o.orders.forEach(o => o.childOrders.forEach(o => o.menuItem = getMenuItem(o.menuItem.id))));
          return o;
        })
      updateCustomerScreen(res);
    } else {
      updateCustomerScreen();
    }
  }
  var thereAreOrders = false;
  var thereAreSelecteOrders = false;
  var thereAreAcceptedOrders = false;
  var printable = 0;
  if (typeof orderListUtil.ordersCache != "undefined")
    orderListUtil.ordersCache.forEach(order => {
      if (localStorage.tableOccupationSelected == order.tableOccupationId && localStorage.tableNumberSelected == order.tableNumber) {
        if (order.state != "cancelled" && order.recordState !== "DELETED") {
          thereAreOrders = true;
          if (order.state != "selected") {
            thereAreAcceptedOrders = true;
          }
        }
        if (order.state == "selected") {
          thereAreSelecteOrders = true;
        }
      }
    });

  if (typeof orderListUtil.orders.tables != "undefined")
    orderListUtil.orders.tables.forEach(order => {
      if (localStorage.tableOccupationSelected == order.tableOccupation && localStorage.tableNumberSelected == order.tableNumber) {
        printable += order.unprinted + order.partprinted;
      }
    });

  $("#_byCustomer.nav-item").hide();
  $("#_byTiming.nav-item").hide();
  $("#_orders.nav-item span").click();
  $("button#lock").removeClass("hidden");

  const isPickup = Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].pickup["table-number"];
  const isHomeDelivery = Number(localStorage.tableNumberSelected) === auth.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"];
  if (isPickup) {
    $("#editTable  button#lock text").html(I18n.t("admin_local.lock_pickup"));
  } else if (isHomeDelivery) {
    $("#editTable  button#lock text").html(I18n.t("admin_local.lock_homedelivery"));
  } else {
    $("#editTable button#lock text").html(admin_local.new_sale);
  }
  if (isThereReceiptsToPrint())
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#payments").removeClass("hidden");
  else
    $("#editTable >.modal-dialog > .modal-content > .modal-footer button#payments").addClass("hidden");


  $("#editTable button#cancel").addClass("hidden");
  $("#editTable button#cancel").attr("disabled", null);
  $("#editTable button#paying").attr("disabled", null);
  $("#editTable button#paid").attr("disabled", null);
  $("#editTable button#submit-new-orders").attr("disabled", null);
  $("#editTable button#submit-and-accept-new-orders").attr("disabled", null);
  $("#editTable button#submit-and-accept-and-print-new-orders").attr("disabled", null);
  $("div#editTable .modal-body > div#container").removeClass("hidden");
  if (thereAreOrders && localStorage.selectNewOrders === "true") {
    $("#editTable button#submit-and-accept-new-orders").removeClass("hidden");
    $("#editTable button#submit-and-accept-new-orders").prop("disabled", !thereAreSelecteOrders);
    $("#editTable button#submit-and-accept-and-print-and-pay-new-orders").removeClass("hidden");
    $("#editTable button#paid").removeClass('hidden');
    if (printable > 0) {
      $("#editTable button#submit-and-accept-and-print-new-orders").removeClass("hidden");
      $("#editTable button#submit-and-accept-new-orders").css("flex", "1");
      $("#editTable button#submit-and-accept-and-print-and-pay-new-orders").addClass("printable");
    } else {
      $("#editTable button#submit-and-accept-and-print-new-orders").addClass("hidden");
      $("#editTable button#submit-and-accept-new-orders").css("flex", "2");
      $("#editTable button#submit-and-accept-and-print-and-pay-new-orders").removeClass("printable");
    }
    $("#editTable button#submit-and-accept-and-print-new-orders-choice").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-choice2").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-drinks").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-meals").addClass("hidden");
  } else if (!thereAreOrders && localStorage.selectNewOrders == "true") {
    $("#editTable button#submit-and-accept-new-orders").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-and-pay-new-orders").addClass("hidden");
    $("#editTable button#paid").addClass('hidden');
    $("#editTable button#submit-and-accept-and-print-new-orders-choice").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-choice2").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-drinks").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-meals").addClass("hidden");
    //$("#editTable button#cancel").removeClass("hidden");
  } else if (thereAreOrders) {
    $("#editTable button#paid").removeClass('hidden');
  } else {
    $("#editTable button#submit-and-accept-new-orders").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-and-pay-new-orders").addClass("hidden");
    $("#editTable button#paid").addClass('hidden');
    $("#editTable button#submit-and-accept-and-print-new-orders-choice").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-choice2").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-drinks").addClass("hidden");
    $("#editTable button#submit-and-accept-and-print-new-orders-meals").addClass("hidden");
    $("#editTable button#cancel").addClass("hidden");
  }


  $("#editTable button#qrcode").attr("disabled", null);
  /*
  if (!isPickup && !isHomeDelivery)
    $("#editTable button#lock").removeClass("hidden");
  else
    $("#editTable button#lock").addClass("hidden");
    */

  if (localStorage.tableOccupationSelected != null && localStorage.tableOccupationSelected != "null" && getTableOccupation(localStorage.tableOccupationSelected) && orderListUtil.tableOccupations)
    $("#editTable button#takeaway").toggleClass("active", getTableOccupation(localStorage.tableOccupationSelected).takeaway);
  else $("#editTable button#takeaway").toggleClass("active", false);

  $("#editTable button#close").removeClass("hidden");
  $("#editTable button#unlock").addClass("hidden");
  $("#editTable button#qrcode").addClass("hidden");

  if (orderListUtil.occupationsSelected && orderListUtil.occupationsSelected.length == 0) {
    $("#editTable button#edit-discount").addClass("hidden");
    $("#editTable button#new-order").addClass("hidden");
    $("#editTable button#paying").addClass("hidden");
    $("#editTable button#cancel").addClass("hidden");
  } else {
    $("#editTable button#edit-discount").removeClass("hidden");
    if (localStorage.selectNewOrders != "true") {
      $("button#new-order").removeClass("hidden");
      $("button#paying").removeClass("hidden");
    } else {
      $("button#paying").removeClass("hidden");
      $("button#new-order").addClass("hidden");
    }
    //$("button#cancel").removeClass("hidden");
  }

  var table = getTable(localStorage.tableNumberSelected);
  if (table == null)
    return;


  var lockNotPossible = (orderListUtil.occupationsSelected.length > 0 && table.shareable != true) || (localStorage.selectNewOrders == "true" && !table.pos);
  $("#editTable button#lock").prop("disabled", lockNotPossible);
  //$("#editTable button#new-order").prop("disabled", orderListUtil.occupationsSelected.length == 0);
  $("#editTable button#qrcode").prop("disabled", orderListUtil.occupationsSelected.length == 0);

  var tableMateChoicesSet = new SortedSet({ comparator: customerComparator });
  var payingTableMatesSet = new SortedSet({ comparator: customerComparator });
  var thereAreNonPayers = false;
  admin.tableMates.forEach(function (tableMateId) {
    var tableMate = getTableMate(tableMateId);
    if (tableMate.tableOccupationId === Number(localStorage.tableOccupationSelected) && tableMate.tableNumber === Number(localStorage.tableNumberSelected)) {
      var t;
      if (tableMate.payingMateId == 0) t = tableMate;
      else t = getTableMate(tableMate.payingMateId);
      if (t == undefined) return;
      if (SortedSet.find(payingTableMatesSet, t) == null) {
        payingTableMatesSet.insert(t);
      }
      if (getStateIndex(t, "wantsToPay") >= 0) {
        if (SortedSet.find(tableMateChoicesSet, t) == null) tableMateChoicesSet.insert(t);
      } else {
        thereAreNonPayers = true;
      }
    }
  });
  $("#editTable button#new-order").attr("disabled", !thereAreNonPayers);
  $("#editTable button#edit-discount").attr("disabled", !thereAreNonPayers);
  $("#editTable button#takeaway").attr("disabled", !thereAreNonPayers);
  var count = getOpenOrdersCount(payingTableMatesSet);
  $("#editTable button#paying").prop("disabled", orderListUtil.occupationsSelected.length == 0 || count == 0);
  $("#editTable button#paid").prop("disabled", count == 0);
  $("#editTable button#closeOrder").addClass("hidden");
  if (count == 0) {
    $("#editTable button#paid").addClass("hidden");
    if ((orderListUtil.occupationsSelected.length > 0 && !thereAreSelecteOrders))
      $("#editTable button#closeOrder").removeClass("hidden");
  } else {
    $("#editTable button#paid").removeClass("hidden");
    $("#editTable button#closeOrder").addClass("hidden");
  }
  //$("#editTable button#submit-and-accept-and-print-and-pay-new-orders").prop("disabled", orderListUtil.occupationsSelected.length == 0 || count == 0);
  if (thereAreAcceptedOrders || (!thereAreSelecteOrders && orderListUtil.occupationsSelected.length <= 1)) {
    $("#editTable button#cancel").prop("disabled", true);
  } else {
    $("#editTable button#cancel").prop("disabled", false);
    if (thereAreOrders)
      $("button#cancel").removeClass("hidden");
  }

  const lessKioskButtons = auth.myStatus.restaurant_settings["tables-overview"].lessKioskButtons;

  if (lessKioskButtons || (orderListUtil.statesShown.length === 1 && orderListUtil.statesShown.indexOf("delivered") !== -1)) {
    $("#editTable button#paying").addClass("hidden");
    $("#editTable button#submit-and-accept-new-orders").addClass("hidden");
    $("#editTable button#paid").addClass("hidden");
  }

  if (isPickup || isHomeDelivery) {
    $("#editTable button#close").removeClass("hidden");
    $("#editTable button#close").attr("disabled", false);
  }

};

export const cancelOrders = openNew => {
  if (isNaN(localStorage.tableOccupationSelected))
    return;
  unlockTable(
    localStorage.tableOccupationSelected,
    localStorage.tableNumberSelected,
    true,
    data => {
      update(data);
      if (orderListUtil.occupationsSelected.length == 0) {
        if (openNew === true) lockTable(localStorage.tableNumberSelected, 1, afterLockTable);
        else fullHideEditTable(true);
      } else {
        selectNewOrders();
      }
    },
    data => { }
  );
};

function getTable(number) {
  var res = null;
  orderListUtil.pointOfSales && orderListUtil.pointOfSales.forEach(function (table) {
    if (table.number == number) res = table;
  });
  return res;
}

export const closeOrder = () => {
  if (localStorage.tableOccupationSelected)
    unlockTable(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, false, function (data) {
      update(data);
      window.hideEditTable(false);
      if (orderListUtil.occupationsSelected.length === 0) {
        lockTable(localStorage.tableNumberSelected, 1, afterLockTable);
      }
    })
}

export const updateRightPane = () => {
  $("#drink_menu .selectable").remove();
  $("#meal_menu .selectable").remove();
  $("#timingSelector").replaceWith($("#timingSelectorTemplate").tmpl(this));
  $("#timingSelector #asap").addClass("active");
  $("#timingSelector #optionasap").prop("checked", true);
  $("#timingSelector").addClass("hidden");

  admin.categories.activeMenuCategories.forEach(function (category) {
    if (category.entityType == "DrinkCategory" && category.isTop)
      $("#categoryTemplate")
        .tmpl(category)
        .appendTo("#drink_menu");
  });
  admin.categories.activeMenuCategories.forEach(function (category) {
    if (category.entityType == "MealCategory" && category.isTop)
      $("#categoryTemplate")
        .tmpl(category)
        .appendTo("#meal_menu");
  });
  $(".menuItemHead").each((ind, e) => e.addEventListener("touchstart", touchStart, {
    capture: true,
    passive: false
  }));
  $(".list-group-item.defaultQuantity").each((ind, e) => e.addEventListener("touchstart", touchStart, {
    capture: true,
    passive: false
  }));

  if (isAdmin(auth.myStatus.roles)) {
    chooseMenuItems.attacheContextMenus();
  }
}

function selectTableOccupation2(tableOccupation) {
  selectTableOccupation(tableOccupation);
  selectNewOrders();
}
